export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    return
  }

  const auth = useAuth()

  if (typeof auth.session === 'undefined') {
    return await new Promise<ReturnType<typeof navigateTo>>((resolve) => {
      watchOnce(
        () => auth.session,
        (value) => {
          if (value !== null && to.path !== '/') {
            resolve(navigateTo('/'))
          }
          else {
            resolve()
          }
        },
        { deep: true },
      )
    })
  }
  else if (auth.session !== null && to.path !== '/') {
    return navigateTo('/')
  }
})
